body {
  font-family: "Barlow Condensed", sans-serif, arial;
  font-size: 18px !important;
}

* {
  font-family: "Barlow Condensed", sans-serif, arial;
  font-size: 18px !important;
}

.css-83ijpv-MuiTypography-root {
  font-family: "Barlow Condensed", sans-serif, arial !important;
}

.Horizontal-Container-Styling {
  padding-left: 0px !important;
  margin-top: 115px !important;
}

.table-empty .icon {
  font-size: 100px !important;
}
