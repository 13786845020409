.autocomplete label {
  color: rgba(255, 255, 255, 0.5) !important;
}

.autocomplete input {
  color: rgba(255, 255, 255, 0.5) !important;
  padding: 0 !important;
}

.autocomplete button {
  color: rgba(255, 255, 255, 0.5) !important;
}

.autocomplete {
  border: 1.5px solid #2b3553 !important;
  padding: 0;
}

.autocomplete:hover {
  border-color: #ba54f5 !important;
}

.autocomplete:focus {
  border-color: transparent !important ;
}

.autocomplete:active {
  border-color: transparent !important ;
}

.autocomplete input:focus {
  border-color: transparent !important ;
}
