.input_date {
  color-scheme: dark;
}
.title_style {
  font-size: 23px !important;
  margin-bottom: 0px !important;
}
.category_style {
  font-size: 14px !important;
  margin-bottom: 0px !important;
}
.iconText-style {
  font-size: 19px !important;
}
.titleSecondCardRow {
  font-size: 23px !important;
}
/* .iconStyle{
  display: flex;
  align-items: center;
} */
/* .data-Style{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
} */
