#form-control {
  display: block !important;
  width: 100% !important;
  height: calc(2.25rem + 2px) !important;
  padding: 0.5rem 0.7rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.42857 !important;
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: transparent !important;
  background-clip: padding-box !important;
  border: 1px solid #cad1d7 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
}
@media (prefers-reduced-motion: reduce) {
  #form-control {
    transition: none !important;
  }
}
#form-control::-ms-expand {
  background-color: transparent !important;
  border: 0 !important;
}
#form-control:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.8) !important;
}
#form-control:focus {
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: #ffffff !important;
  border-color: rgba(50, 151, 211, 0.25) !important;
  outline: 0 !important;
}
#form-control::placeholder {
  color: #adb5bd !important;
  opacity: 1 !important;
}
#form-control:disabled,
#form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}
