.pointer {
  cursor: pointer !important;
}
.onLight-item {
  display: flex;
}
.onLight-item + .onLight-item {
  padding-left: 0.5rem;
}
.onLight-item + .onLight-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: black !important;
  content: "/";
}
.onLight-item + .onLight-item:hover::before {
  text-decoration: underline;
}
.onLight-item + .onLight-item:hover::before {
  text-decoration: none;
}
.onLight-item.active {
  color: #ffffff;
}
