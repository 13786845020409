.autocomplete label {
  color: white !important;
}

.autocomplete input {
  color: white !important;
}

.autocomplete button {
  color: white !important;
}
  