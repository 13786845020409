#table-borderless td,
#table-borderless th {
  border: 0 !important;
  border-bottom: 1px solid rgb(224, 220, 220) !important;
  font-size: 0.75rem !important;
}

#right-align {
  text-align: right !important;
}

#center-align {
  text-align: center !important;
}
