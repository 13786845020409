.k-pdf-export #thOnPDF {
  display: flex !important;
  flex-wrap: wrap !important;
}
.k-pdf-export .CFU {
  justify-content: right !important;
  text-align: right !important;
  width: 87px !important;
}
.k-pdf-export .FU {
  justify-content: right !important;
  width: 80px !important;
}
.k-pdf-export .NAV {
  justify-content: right !important;
  margin-right: 14px !important;
}
.k-pdf-export .GA {
  justify-content: right !important;
  width: 98px !important;
}
.k-pdf-export .SL {
  justify-content: right !important;
  width: 93px !important;
}
.k-pdf-export .RL {
  justify-content: right !important;
  text-align: right !important;
  align-items: end !important;
  width: 88px !important;
}
.k-pdf-export .CGT {
  justify-content: right !important;
  text-align: right !important;
  margin-right: 14px !important;
}
.k-pdf-export .NA {
  justify-content: right !important;
  text-align: right !important;
  width: 90px !important;
}
.k-pdf-export .CU {
  justify-content: right !important;
  text-align: right !important;
  margin-right: 47px !important;
}
.k-pdf-export .tddate {
  justify-content: left !important;
  text-align: left !important;
  width: 47px !important;
}
.k-pdf-export .tdTXNID {
  justify-content: left !important;
  text-align: left !important;
  width: 92px !important;
  word-break: break-word !important;
  /* background: palegreen !important; */
}
.k-pdf-export .tdDescription {
  justify-content: left !important;
  text-align: left !important;
  width: 98px !important;
}
.k-pdf-export .tdCPF {
  justify-content: right !important;
  text-align: right !important;
  width: 115px !important;
  word-break: break-word !important;
  padding-right: 8px !important;
}
.k-pdf-export .tdFU {
  justify-content: right !important;
  text-align: right !important;
  width: 79px !important;
  word-break: break-word !important;
  /* background: blue; */
}
.k-pdf-export .tdNAV {
  justify-content: right !important;
  text-align: right !important;
  width: 48px !important;
  word-break: break-word !important;
  /* background: green; */
}
.k-pdf-export .tdGA {
  justify-content: right !important;
  text-align: right !important;
  width: 70px !important;
  word-break: break-word !important;
  /* background: red; */
}
.k-pdf-export .tdSL {
  justify-content: right !important;
  text-align: right !important;
  width: 78px !important;
  word-break: break-word !important;
  /* background: red !important; */
}
.k-pdf-export .tdRD {
  justify-content: right !important;
  text-align: right !important;
  width: 78px !important;
  word-break: break-word !important;
  /* background: red !important; */
}
.k-pdf-export .tdCGT {
  justify-content: right !important;
  text-align: right !important;
  width: 48px !important;
  word-break: break-word !important;
  /* background: red !important; */
}
.k-pdf-export .tdNA {
  justify-content: left !important;
  text-align: left !important;
  width: 95px !important;
  word-break: break-word !important;
  /* background: red !important; */
}
.k-pdf-export .tdCU {
  justify-content: right !important;
  text-align: right !important;
  width: 155px !important;
  word-break: break-word !important;
  /* background: red !important; */
}

.k-pdf-export .openingBalancePDF {
  display: flex !important;
  column-gap: 45px !important;
  margin-right: 5px !important;
}
.k-pdf-export .closingBalancePDF {
  width: fit-content !important;
  display: flex !important;
  column-gap: 45px !important;
  margin-right: 5px !important;
}
