
.heading_text{
  color: white !important;
  text-wrap: nowrap;
  
}
.vertical-divider_right {
  width: 1px;
  background-color: #ccc;
  height: 40px;
  margin: 8px 5px 0px 5px;
  position: absolute;
  left: -35px;
}
.vertical-divider {
  width: 1px;
  background-color: #ccc;
  height: 40px;
  margin: 8px 5px 0px 5px;
  /* position: absolute; */
  right: -35px;
}
.SubMenuTest{
  color: white;
}
.SubMenuTest:hover {
  color: #ba54f5 ;
}
#activeStyle{
  color: #ba54f5 !important;
}

.popOver-Style{
  background: linear-gradient(0deg, #0057f4 0, #002166 100%) !important;
}
.popOver-Styleredish{
  background: linear-gradient(rgba(102, 51, 0, 1) 0%, rgba(255, 51, 153, 1) 100%) !important;
}
.popOver-Stylebrownish{
  background: linear-gradient(to right, rgb(120 108 95) 0%, rgb(48, 67, 82) 100%);
}
.horizontal-children-li{
  border-bottom: 1px solid;
  /* color: white; */
}
.horizontal-subMenuDesign{
  /* background: #1e1e2f; */
  background: linear-gradient(0deg, #0057f4 0, #002166 100%);;
  width: 100%;
    flex-direction: column;
    /* text-wrap: nowrap; */
    border-radius:4px;
    border: 1px solid
}
.horizontal-sidebar-aTag{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.horizontal_ul{
  justify-content: space-around;
}
.Horizontal-Sidebar {
  z-index: 999;
  width: 97vw;
  position: absolute;
  /* height: 10vh; */
  max-height: 105px;
  padding: 24px 0px;
  /* height: auto; */
  top: 88px;
  left: 15px;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, .2), 0 2px 30px 0 rgba(0, 0, 0, .35);
  /* background-color: #1e1e2f; */
  background: linear-gradient(0deg, #0057f4 0, #002166 100%);
  border-radius: 6px;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.ProfileCard-details {
  padding-left: 30px;
  float: left;
}
#onhover{
  border-color:#2b3553 !important;

}
.ProfileCard {
  position: relative;
  padding: 8px;
  border: none !important;
}
.ProfileCard-avatar {
  position: absolute;
  border-radius: 5px;
  color: white;
}
.realname{
  color: white;
}
.realname:hover,
.realname:active{
  color: #ba54f5;
}
.Typeahead-menu.is-open {
  display: block;
}
.Typeahead-menu {
  position: absolute;
  max-height: 300px;
  top: 120%;
  padding: 12px;
  z-index: 100;
  display: none;
  width: 100%;
  overflow: auto;
  background: linear-gradient(0deg, #0057f4 0, #002166 100%);
  border:1px solid white;
  border-radius: 15px;
  box-shadow: 0 0 0 1px green;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}
.Typeahead-menu-new.is-open {
  display: block;
}
.Typeahead-menu-new {
  position: absolute;
  max-height: 300px;
  top: 120%;
  padding: 12px;
  z-index: 100;
  display: none;
  width: 100%;
  overflow: auto;
  background: linear-gradient(0deg, #0057f4 0, #002166 100%);
  border:1px solid white;
  border-radius: 15px;
  box-shadow: 0 0 0 1px green;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}
#text-onHover:hover{
  color:#e14eca !important
}
.active-Menu{
  color:#e14eca !important
}
.search-form input {
  border: 1px solid #eff0f1;
  padding: 10px 10px 10px 70px;
  border-radius: 50px;
}
.dropdownStyling{
  border-radius: 4px;
  background-color: #1e1e2f !important;
  color:white;
  top: 45px;
  z-index: 999;
}

.custom_style_item{
  color:white !important
}
.custom_style_item:hover{
  color:black !important
}
.paddingOnVertical{
  padding-right: 7px;
  /* padding-left: 7px; */
}
#logo-After{
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    z-index: -2;
}
#logo-After::after{
    content: "";
    position: absolute;
    top: 0;
    right: 15px;
    height: 1px;
    width: calc(100% - 30px);
    background:transparent;
}
.sidebar-view {
  transition: transform 0.5s;
  transform: rotate(90deg);
  cursor: pointer;
  padding: 3px;
  background: #053497;
}
.sidebar-view:hover {  
  background: #042d85;
}

.sidebar-view.rotate {
  transform: rotate(180deg);  
  cursor: pointer;
  padding: 3px;
  background: #053497;
}
.sidebar-view.rotate:hover {  
  background: #042d85;
}
.sidebar-viewredish {
  transition: transform 0.5s;
  transform: rotate(90deg);
  cursor: pointer;
  padding: 3px;
  background: rgb(227 54 140);
}
.sidebar-viewredish:hover {  
  background: rgb(218 32 124);
}

.sidebar-viewredish.rotate {
  transform: rotate(180deg);  
  cursor: pointer;
  padding: 3px;
  background: rgb(227 54 140);
}
.sidebar-viewredish.rotate:hover {  
  background: rgb(218 32 124);
}
.sidebar-viewbrownish {
  transition: transform 0.5s;
  transform: rotate(90deg);
  cursor: pointer;
  padding: 3px;
  background: rgb(120 108 95);
}
.sidebar-viewbrownish:hover {  
  background: rgb(75, 72, 69);
}

.sidebar-viewbrownish.rotate {
  transform: rotate(180deg);  
  cursor: pointer;
  padding: 3px;
  background: rgb(91 86 80);
}
.sidebar-viewbrownish.rotate:hover {  
  background: rgb(75, 72, 69);
}
/* .toggle_icon{
  position: absolute;
} */

.horizontal-sidebar {
  background: linear-gradient(0deg, #0057f4 0% 0%, #002166 100% 100%);
  background: #ba54f5;
  background: linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
  height: calc(100vh - 90px);
  width: 230px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  display: block;
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.6);
  margin-top: 80px;
  margin-left: 20px;
  border-radius: 5px;
  transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
@media (min-width: 991px) {
  .horizontal-sidebar {
      display: block;
      z-index: 1030;
      box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2), 0px 2px 30px 0 rgba(0, 0, 0, 0.35);
  }
}
@media (min-width: 991px) {
  .sidebar-mini .sidebar {
      width: 90vw;
  }
}

.sidebar .nav p {
  font-family: "Barlow Condensed", sans-serif, arial;
}

.sidebar .nav li > a > span {
  font-family: "Barlow Condensed", sans-serif, arial;
}

.sidebar .nav {
  background: #00000026;
}

.sidebar .nav li {
  font-family: "Barlow Condensed", sans-serif, arial;
  border-bottom: 1px solid #222a42;
  /* background: #00000026; */
}

.sidebar {
  font-size: 22px !important;
}
